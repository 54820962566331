import Skeleton from './Skeleton'

const SkeletonCollection = ({
  length,
  animation,
  size,
  wrapperClass,
  instanceClass,
  skeletonClass
} = { length: 20 }) => {
  const collection = Array.from({ length })
  return (
    <div className={wrapperClass}>
      {collection.map((_, i) => (
        <div key={i.toString()} className={instanceClass}>
          <Skeleton animation={animation} size={size} wrapperClass={skeletonClass} />
        </div>
      ))}
    </div>
  )
}

export default SkeletonCollection
