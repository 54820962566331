import React, { useEffect, useRef, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SkeletonCollection from './components/SkeletonCollection'
import { FaFilter } from 'react-icons/fa'
import SubmitCompanyForm from './components/SubmitCompanyForm'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)

function Home() {
  const [companies, setCompanies] = useState([])
  const [industries, setIndustries] = useState([])
  const [selectedIndustry, setSelectedIndustry] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    fetchCompanies().catch(console.error)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  async function fetchCompanies() {
    const companyCollection = collection(db, 'companies')
    const companySnapshot = await getDocs(companyCollection)
    const companyList = companySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))

    const companiesWithUrls = await Promise.all(
      companyList.map(async (company) => {
        try {
          const logoUrl = company.logo
            ? await getDownloadURL(ref(storage, company.logo))
            : null
          return {
            ...company,
            logoUrl,
            url: typeof company.url === 'object' ? company.url.text : company.url
          }
        } catch (error) {
          console.log('Error getting logo URL: ', error)
          return company
        }
      })
    )

    const sortedCompanies = companiesWithUrls.sort(
      (a, b) => (b.priority || 0) - (a.priority || 0)
    )

    setCompanies(sortedCompanies)

    const allIndustries = Array.from(
      new Set(
        companyList
          .flatMap((company) => {
            if (typeof company.industry === 'string') {
              try {
                const parsedIndustry = JSON.parse(company.industry)
                return Array.isArray(parsedIndustry)
                  ? parsedIndustry
                  : [parsedIndustry]
              } catch (e) {
                return [company.industry]
              }
            } else if (Array.isArray(company.industry)) {
              return company.industry
            } else {
              return []
            }
          })
          .filter((industry) => industry.trim() !== '')
      )
    ).sort()

    setIndustries(allIndustries)
  }

  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  const handleCompanyClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  const handleIndustrySelect = (industry) => {
    setSelectedIndustry(industry)
    setShowDropdown(false)
  }

  const handleFilterButtonClick = () => {
    setShowDropdown(!showDropdown)
  }

  const filteredCompanies = selectedIndustry
    ? companies.filter((company) => {
      if (typeof company.industry === 'string') {
        try {
          const parsedIndustry = JSON.parse(company.industry)
          if (Array.isArray(parsedIndustry)) {
            return parsedIndustry.includes(selectedIndustry)
          } else {
            return parsedIndustry === selectedIndustry
          }
        } catch (e) {
          return company.industry === selectedIndustry
        }
      } else if (Array.isArray(company.industry)) {
        return company.industry.includes(selectedIndustry)
      } else {
        return false
      }
    })
    : companies

  return (
    <div className="container pt-4">
      <div className="row mb-3 d-flex align-items-center">
        <div className="col-8">
          <h1 className="eb-garamond mb-0">Toledo Tech Directory</h1>
          <span className="eb-garamond mb-0">
            <a
              href="https://www.empoweredai.org/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit', textDecoration: 'none', fontStyle: 'italic' }}
            >
              presented by EmpoweredAI
            </a>
          </span>
        </div>
        <div className="col-4 d-flex justify-content-end" ref={dropdownRef}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="filter-tooltip" className="custom-tooltip">Filter by Capabilities</Tooltip>}
          >
            <button
              className="custom-filter-btn me-2"
              onClick={handleFilterButtonClick}
            >
              <FaFilter size={20} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip" className="custom-tooltip">Add / Claim / Update Company</Tooltip>}
          >
            <Link to="/submit-company" className="custom-btn">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 5V19"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 12H19"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </OverlayTrigger>

          {showDropdown && (
            <Dropdown.Menu show className="position-absolute mt-2">
              <Dropdown.Item onClick={() => handleIndustrySelect('')}>
                All
              </Dropdown.Item>
              {industries.map((industry, index) => (
                <Dropdown.Item key={index} onClick={() => handleIndustrySelect(industry)}>
                  {industry}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </div>
      </div>

      {filteredCompanies?.length === 0 && (
        <SkeletonCollection
          animation='glow'
          length={60}
          wrapperClass={"row row-cols-auto justify-content-center mt-5 company-list"}
          instanceClass={"col mb-4 d-flex justify-content-center"}
          skeletonClass={'company'}
        />
      )}

      {filteredCompanies?.length > 0 && (
        <div className="row row-cols-auto justify-content-center mt-5 company-list">
          {filteredCompanies.map((company, index) => (
            <div
              key={company.id}
              className={`col mb-4 d-flex justify-content-center ${company.size === 'large' ? 'large' : ''}`}
            >
              <div className="company" onClick={() => handleCompanyClick(company.url)}>
                {company.logoUrl && (
                  <LazyLoadImage
                    src={company.logoUrl}
                    alt={company.organization_name}
                    className="img-fluid"
                  />
                )}
                <div className="company-overlay">
                  <div className="company-name">
                    {company.organization_name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/submit-company" element={<SubmitCompanyForm />} />
      </Routes>
    </Router>
  )
}

export default App
