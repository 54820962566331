const Skeleton = ({ animation, size, wrapperClass } = { animation: 'glow', wrapperClass: '' }) => {
  let className = `placeholder w-100 h-100`
  if (size !== undefined) {
    className = `${className} placeholder-${size}`
  }
  return (
    <div className={`placeholder-${animation} skeleton ${wrapperClass}`}>
      <span className={className}></span>
    </div>
  )
}

export default Skeleton
